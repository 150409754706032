import React from 'react';
import MaterialTable from 'material-table';

const CorroborarData=(data)=>{
    if (
    !data.marca||
    !data.tipo||
    !data.patente||
    data.marca.trim()==""||
    data.tipo.trim()==""||
    data.patente.trim()==""){
      alert("Faltan completar campos")
      
      return false;
    }
    else{
        return true;
    }
}

export default function MaterialTableDemo({data,setData}) {
   const columns= [
      { title: 'Patente', field: 'patente' },
      { title: 'Marca', field: 'marca' },
      { title: 'Tipo',  field: 'tipo',lookup: { 'auto': 'Auto', 'motorhchico': 'Motorhome chico','motorhgrande': 'Motorhome grande','camper': 'Camper'  }, },
    ]

  return (
    <MaterialTable
      title="Vehiculos"
      columns={columns}
      data={data}
      style={{textAlign:"right !important",padding:'10px'}}
      localization={{
            body:{
            addTooltip:"Agregar Vehiculo",
            emptyDataSourceMessage:"No se cargaron vehiculos",
            deleteTooltip:"Eliminar",
            editTooltip:"Editar",
            editRow:{
            deleteText:"Seguro que quieres eliminar el vehiculo?",
            cancelTooltip:"Cancelar",
            saveTooltip:"Guardar"}},
            header:{actions:"Acciones"}}}
      options={{
        showTitle:false,
        paging:false,
        search: false,
        sorting:false,
        draggable:false
      }}
      editable={{
        onRowAdd: newData =>{
            if (CorroborarData(newData))
            {

                setData([...data,{...newData,marca:newData.marca.trim(),patente:newData.patente.trim().split(' ').join("").split("-").join("").split("'").join("").toUpperCase(),tipo:newData.tipo}]);
            }
           return( new Promise(resolve => {
                resolve();    
        }))},

        onRowUpdate: (newData, oldData) =>
        {
            if (oldData&&CorroborarData(newData)) {
                console.log(data.indexOf(oldData))
                setData(data.map(x=>x==oldData?{...newData,marca:newData.marca.trim(),patente:newData.patente.trim().split(' ').join("").split("-").join("").split("'").join("").toUpperCase(),tipo:newData.tipo}:x))}
                return(new Promise(resolve => {
              resolve();
          }))},

        onRowDelete: oldData =>
          new Promise(resolve => {

              resolve();
              setData(data.filter(x=>x!=oldData));

          }),
      }}
    />
  );
}