import React,{useState,useEffect,useRef } from "react"
import { navigate  } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import moment from "moment"
import 'moment/locale/es'  
import TablaVehiculos from '../../components/camping/tablaVehiculos'
import TablaParcelas from '../../components/camping/tablaParcelas'
import LoadingOverlay from 'react-loading-overlay';
import Amplify, {API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../../aws-exports'; 
import listaPrecios from "../../Constantes/tarifas.json"
//import CanvasDraw from "react-canvas-draw";
import parcelasAux from '../../Constantes/parcelas.json'
import nacionalidades from '../../Constantes/paises.json'
import provincias from '../../Constantes/provincias.json'
import {MandarFormulario,CargarDNI,CargarCP,CapitalFirst} from '../../Funciones/utils'
import {connect} from 'react-redux'

import { Button,Col,Row,Input,InputGroup,InputGroupAddon,} from 'reactstrap';


Amplify.configure(awsconfig)
moment.locale('es')

export const Nuevo = ({ingreso,dispatch,testFunction}) => 
{  
const [nombre, setNombre] = useState("");
const [edad, setEdad] = useState("");
const [provincia, setProvincia] = useState("Buenos Aires");
const [nacionalidad, setNacionalidad] = useState("Argentina");
const [cuidad, setCuidad] = useState("");
const [dni, setDni] = useState("");
const [vehiculos, setVehiculos] = useState([]);
const [parcelas, setParcelas] = useState([]);
const [menores, setMenores] = useState(0);
const [mayores,setMayores] = useState(2);
const [carpas, setCarpas] = useState(0);
const [trailers, setTrailers] = useState(0);
const [loading, setLoading] = useState(false);
const [color, setColor] = useState("info");
const [dimension, setDimension] = useState(0);
const [buscandoCP, setBuscandoCP] = useState(false);
const [buscandoDNI, setBuscandoDNI] = useState(false);
const canvasRef = useRef(null);
useEffect(() => {
  setDimension(window.innerWidth-40)
  setColor(ingreso?"warning":"info")
  if (ingreso)
  {
    setNombre(ingreso.nombreyape)
    setDni(ingreso.dni)
    setEdad(ingreso.edad.toString())
    setNacionalidad(ingreso.nacionalidad)
    setCuidad(ingreso.ciudad)
    setVehiculos(ingreso.vehiculos)
    setParcelas(ingreso.parcelas)
    setMenores(ingreso.menores)
    setMayores(ingreso.mayores)
    setCarpas(ingreso.carpas)
    setProvincia(ingreso.provincia)
    setTrailers(ingreso.trailers)
  }
 },[]);
if (canvasRef.current) {
  // this will get the canvas HTML element where everyhting that's painted is drawn
  // and call the toDataURL() function on it
  //console.log(canvasRef.current.canvasContainer.children[1].toDataURL());
}


  var total=0;
  total+=listaPrecios.menor*menores;
  total+=listaPrecios.mayor*mayores;
  total+=listaPrecios.carpa*carpas;
  total+=listaPrecios.remolque*trailers;
  total+=listaPrecios.auto*vehiculos.filter(x=>(x.tipo=="auto")).length;
  total+=listaPrecios.camper*vehiculos.filter(x=>(x.tipo=="camper")).length;
  total+=listaPrecios.mchico*vehiculos.filter(x=>(x.tipo=="motorhchico")).length;
  total+=listaPrecios.mgrande*vehiculos.filter(x=>(x.tipo=="motorhgrande")).length;
  navigate("/dormis")
return (
    <LoadingOverlay
    active={loading}
    spinner
    text='Agregando'
    >
    
    <Row>
      <Col xs={12} md={3}> <h2>Nombre y Apellido</h2><Input disabled={buscandoDNI} value={nombre} onChange={(e)=>setNombre(e.target.value)}></Input></Col>
      <Col xs={12} md={1}> <h2>Edad</h2><Input value={edad}  onChange={(e)=>setEdad(e.target.value)}></Input></Col>
      <Col xs={12} md={3}> <h2>Nacionalidad</h2><Input value={nacionalidad} onChange={(e)=>{if(e.target.value!="Argentina"){setCuidad(e.target.value);setProvincia(e.target.value)};setNacionalidad(e.target.value)}} type="select">{nacionalidades.map((x,i)=>(<option key={i}>{x}</option>))}</Input></Col>
      <Col xs={12} md={2}> 
        <h2>Dni/Passp</h2>
        <InputGroup>
        <Input value={dni} disabled={buscandoDNI} onKeyDown={(e)=>{if(e.keyCode == 13){CargarDNI(dni,setBuscandoDNI,setNombre)}}} onChange={(e)=>setDni(e.target.value)}></Input>
          <InputGroupAddon addonType="append">
            <Button onClick={()=>{
              if(dni.trim()!="")
              {CargarDNI(dni,setBuscandoDNI,setNombre)}
              else{
                alert("Para buscar hay que agregar dni")
              }
            
            }} color={"info"}>🔎</Button>   
          </InputGroupAddon> 
        </InputGroup>
      </Col>

      <Col xs={12} md={2}> <h2>Cuidad/CP</h2>
        <InputGroup>
        <Input disabled={nacionalidad!="Argentina"||buscandoCP} 
              value={cuidad} 
              onKeyDown={(e)=>{if(e.keyCode == 13){CargarCP(cuidad,setBuscandoCP,setCuidad,setProvincia)}}}
              onChange={(e)=>setCuidad(e.target.value)}></Input>
            <InputGroupAddon addonType="append">
              <Button onClick={()=>{
                if(cuidad.trim()!="")
                {CargarCP(cuidad,setBuscandoCP,setCuidad,setProvincia)}
                else{
                  alert("Para buscar hay que agregar codigo postal")
                }
              
              }} color={"info"}>🔎</Button>   
            </InputGroupAddon> 
          </InputGroup>
     </Col>
    </Row>
    <br></br>
    <Row>
    <Col xs={12}  md={{size:2,offset:2}}> <h2>Provincia</h2><Input disabled={nacionalidad!="Argentina"||buscandoCP} type="select" value={provincia} onChange={(e)=>setProvincia(e.target.value)}>{provincias.map((x,i)=>(<option key={i}>{x}</option>))}</Input>
    </Col>
  
    <Col xs={12} md={{size:4}} ><h2>Vehiculos</h2>
    <TablaVehiculos data={vehiculos} setData={setVehiculos}></TablaVehiculos>
    </Col>

    <Col xs={12} md={2}> 
    <h2>Parcela</h2>
    <TablaParcelas data={parcelas} setData={setParcelas} parcelasDisponibles={parcelasAux}></TablaParcelas>
    </Col>
    </Row>
    
<br></br>
  <Row>
    <Col xs={12} md={{size:5 ,offset:1}} style={(menores!=0)?{backgroundColor:"#3ef6ff54",borderRadius:"10px"}:{}}>
    <h2>Menor</h2>
    <Button color={color} onClick={()=>{setMenores(menores-1)}} style={{fontSize:"20px",margin:"1rem"}}>-</Button>  
    <div style={{display:"inline-block",fontSize:"1.7rem",fontWeight:500}}>{menores}</div>
    <Button color={color} onClick={()=>{setMenores(menores+1)}} style={{fontSize:"20px",margin:"1rem"}}>+</Button>  
    </Col>
    <Col xs={12} md={5} style={(mayores!=0)?{backgroundColor:"#3ef6ff54",borderRadius:"10px"}:{}}>
    <h2>Mayor</h2>
    <Button color={color} onClick={()=>{setMayores(mayores-1)}} style={{fontSize:"20px",margin:"1rem"}}>-</Button>  
    <div style={{display:"inline-block",fontSize:"1.7rem",fontWeight:500}}>{mayores}</div>
    <Button color={color} onClick={()=>{setMayores(mayores+1)}} style={{fontSize:"20px",margin:"1rem"}}>+</Button>  
    </Col>
    <Col xs={12} md={{size:5 ,offset:1}} style={(carpas!=0)?{backgroundColor:"#3ef6ff54",borderRadius:"10px"}:{}}>
    <h2>Carpa</h2>
    <Button color={color} onClick={()=>{setCarpas(carpas-1)}} style={{fontSize:"20px",margin:"1rem"}}>-</Button>  
    <div style={{display:"inline-block",fontSize:"1.7rem",fontWeight:500}}>{carpas}</div>
    <Button color={color} onClick={()=>{setCarpas(carpas+1)}} style={{fontSize:"20px",margin:"1rem"}}>+</Button>  
    </Col>
    <Col xs={12} md={5} style={(trailers!=0)?{backgroundColor:"#3ef6ff54",borderRadius:"10px"}:{}}>
    <h2>Remolque</h2>
    <Button color={color} onClick={()=>{setTrailers(trailers-1)}} style={{fontSize:"20px",margin:"1rem"}}>-</Button>  
    <div style={{display:"inline-block",fontSize:"1.7rem",fontWeight:500}}>{trailers}</div>
    <Button color={color} onClick={()=>{setTrailers(trailers+1)}} style={{fontSize:"20px",margin:"1rem"}}>+</Button>  
    </Col>
  </Row>
  <br></br>
  {/*<Row >
    <Col xs={12} md={{size:3,offset:2}}><h3>Cantidad noches</h3></Col>
    <Col xs={12} md={2}> 
      <Button color={color} onClick={()=>{setNoches(noches-1)}} style={{fontSize:"20px",margin:"1rem"}}>-</Button>  
      <div style={{display:"inline-block",fontSize:"1.7rem",fontWeight:500}}>{noches}</div>
      <Button color={color} onClick={()=>{setNoches(noches+1)}} style={{fontSize:"20px",margin:"1rem"}}>+</Button></Col>
    <Col xs={12} md={{size:3,offset:1}}><h3>Salida: {salida.format('dddd DD - hh:mm a')}</h3></Col>
  </Row>*/}
  <br></br>
  <Row>
    <Col xs={12} md={{size:3,offset:2}}><h3>Precio por noche</h3></Col>
    <Col xs={6} md={2}><h3>$ {parseInt(total)}</h3></Col>
  </Row>
 { /*<Row >
    <Col xs={12} md={{size:3,offset:2}} ><h3>Total a pagar</h3></Col>
    <Col xs={6} md={2}>{(totalModificable==-1)?
    <h3 onClick={()=>{setTotalModificable(total*noches+mediaEstadia)}}>${total*noches+mediaEstadia}</h3>:
    <Input value={(total==null)?0:total*noches+mediaEstadia} onChange={(e)=>setTotalModificable(e.target.value==""?0:e.target.value)}></Input>
  }</Col>
    <Col xs={2}>
    <FormGroup check >
          <Label check>
            <Input checked={mediaEstadia!=0} onChange={(e)=>{setSalida(moment(fechaIngreso.format()).add("days",noches).set({hour:!e.target.checked?11:18,minute:0,second:0,millisecond:0}));setMediaEstadia(e.target.checked?total/2:0)}} type="checkbox" name="checkbox1" />{' '}
            Media Estadia
          </Label>
        </FormGroup>
    </Col>
  </Row>*/}
  <br></br>
  {/*<Row style={{textAlign:"left"}}>
    <Col xs={12} md={{size:3,offset:3}}><h3>Firma</h3></Col>
    <Col xs={12} md={2}> 
      <Button color={color} size={"large"} onClick={()=>{canvasRef.current.clear()}} style={{fontSize:"20px",margin:"1rem"}}>Limpiar</Button> 
    </Col>
  </Row>
  <Col xs={12} style={{border:"solid",borderColor:"black"}}>
    <CanvasDraw  
    brushRadius={2}
    ref={canvasRef}
    lazyRadius={2}
    canvasWidth={dimension}
    canvasHeight={200}/>
</Col>*/}
  <br></br>
  <Row>
    <Col xs={12} md={{size:3,offset:4}} ><Button size="lg" id="buttonTest" color={"success"} onClick={()=>{
      if (nombre.trim()==""||
      edad.trim()==""||
      nacionalidad.trim()==""||
      cuidad.trim()==""||
      dni.trim()==""){
      alert("Faltan completar campos")
      }
      else{
        setLoading(true);
        MandarFormulario(
        nombre.trim().toLocaleLowerCase().split("'").join("").split("-").join(" ").split(" ").map(x=>CapitalFirst(x)).join(" "),
        edad.trim().toLocaleLowerCase(),nacionalidad,cuidad.trim().toLocaleLowerCase().split(" ").map(x=>CapitalFirst(x)).join(" "),
        provincia,dni.trim(),
        vehiculos.map(x=>{
          let {tableData,...y}=x
          return(y)
        }),
        parcelas.map(x=>{
          let {tableData,...y}=x
          return(y.parcela)
        }),
        menores,
        mayores,
        carpas,
        trailers,
        ingreso?ingreso.fechaIngreso:moment().add(3, 'hours').format(),
        ingreso?ingreso.fechaSalida:moment().set({hour:11,minute:0,second:0,millisecond:0}).format(),
        total,
        ingreso?ingreso.listaPagos:[],
        ingreso?ingreso.checkout:"0000-00-00 00:00:00",
        setLoading,
        ingreso?ingreso.idingresos:null,
        ingreso!=null,
        dispatch,
        testFunction)}}
        
      }
     >{color=="warning"?"Editar":"Checkin"}</Button></Col>
  </Row>
  </LoadingOverlay>
 
)}

const Wrapper=(props)=>(
  <Layout>
    <SEO title="Nuevo Registro" />
    <Nuevo {...props}></Nuevo>
  </Layout>
)

export default connect(state=>({ingreso:state.camping.ingreso}),null)(Wrapper)
