import React from 'react';
import MaterialTable from 'material-table';

const CorroborarData=(data)=>{
  console.log(data)
    if (
    !data.parcela||
    data.parcela.trim()==""||
    data.parcela.trim()=="0"){
      alert("Faltan completar campos")
      
      return false;
    }
    else{
        return true;
    }
}

export default function MaterialTableDemo({data,setData,parcelasDisponibles}) {
/*console.log(data)*/
   const columns= [
      { title: 'Parcela', field: 'parcela',lookup: Object.assign({}, ...parcelasDisponibles.map(item => ({ [item]: item })))},
    ]

  return (
    <MaterialTable
      title="Parcelas"
      columns={columns}
      data={data}
      style={{textAlign:"right !important",padding:'10px'}}
      localization={{
            body:{
            addTooltip:"Agregar Parcela",
            emptyDataSourceMessage:"No se cargaron parcelas",
            deleteTooltip:"Eliminar",
            editTooltip:"Editar",
            editRow:{
            deleteText:"Eliminar parcela?",
            cancelTooltip:"Cancelar",
            saveTooltip:"Guardar"}},
            header:{actions:"Acciones"}}}
      options={{
        showTitle:false,
        paging:false,
        search: false,
        sorting:false,
        draggable:false
      }}
      editable={{
        onRowAdd: newData =>{
            if (CorroborarData(newData))
            {

                setData([...data,{parcela:newData.parcela}]);
            }
           return( new Promise(resolve => {
                resolve();    
        }))},

        onRowUpdate: (newData, oldData) =>
        {
            if (oldData&&CorroborarData(newData)) {
                console.log(data.indexOf(oldData))
                setData(data.map(x=>x==oldData?{parcela:newData.parcela}:x))}
                return(new Promise(resolve => {
              resolve();
          }))},

        onRowDelete: oldData =>
          new Promise(resolve => {

              resolve();
              setData(data.filter(x=>x!=oldData));

          }),
      }}
    />
  );
}